export const riskOptions = [
  {
    label: 'All',
    value: "all"
  },
  {
    label: 'Identified',
    value: true
  },
  {
    label: 'Unidentified',
    value: false
  },
]

export const pagesOptions = [5, 10,20, 50, 100]

export const addressNumberOptions = [
  {
    label: 'Any',
    value: "any"
  },
  {
    label: 'More than',
    value: 'more'
  },
  {
    label: 'Less',
    value: 'less'
  },
]

export const percentOfTxs = [
  {
    label: 'More than',
    value: '$gt'
  },
  {
    label: 'Less than',
    value: '$lt'
  },
  {
    label: 'More or equal',
    value: '$gte'
  },
  {
    label: 'Less or equal',
    value: '$lte'
  },
]

export const alertLogicOperators = [
  {
    label: 'AND',
    value: '$allOf'
  },
  {
    label: 'OR',
    value: '$anyOf'
  },
]

export const alertDirection = [
  {
    label: 'Incoming txs',
    value: 'directTx'
  },
]

export const alertPriority = [
  {
    label: 'High',
    value: 100
  },
  {
    label: 'Medium',
    value: 50
  },
  {
    label: 'Low',
    value: 0
  },
]

export const alertChanel = [
  {
    label: 'Dashboard only',
    value: 'dashboard'
  },
  {
    label: 'Email',
    value: 'email'
  }
]

export const quantityPropsList = [
  {
    label: 'BTC',
    key: 'amount',
  },
  // {
  //   label: 'Share',
  //   key: 'share',
  // },
]

export const alertsTriggersList = [
  {
    label: 'Received',
    value: 'address'
  },
  {
    label: 'Received from “Owner”',
    value: 'owner'
  },
  {
    label: 'Received from “Score”',
    value: 'funds.score'
  },
  {
    label: 'Received from “Type / Tag”',
    value: 'funds.id'
  }
]
